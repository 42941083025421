import { Dimensions, StyleSheet } from "react-native";

import { FontFamily } from "../../assets/fonts";
export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  containerDark: {
    flex: 1,
    // backgroundColor: "#0D0B12",
  },
  logo: {
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    // flex: 1,
    top: "15%",
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
  titleText: {
    fontStyle: "normal",
    fontFamily: FontFamily.InterRegular,
    fontSize: 22,
    textAlign: "center",
    letterSpacing: 0.2,
    lineHeight: 41,
    color: "#007AFF",
  },
  headerText: {
    fontStyle: "normal",
    fontFamily: FontFamily.InterExtraBold,
    fontSize: 22,
    textAlign: "center",
    letterSpacing: 1,
    lineHeight: 41,
    color: "#007AFF",
  },
  subHeaderText: {
    fontFamily: FontFamily.InterRegular,
    fontSize: 14,
    textAlign: "center",
    lineHeight: 22,
    color: "#444444",
    fontStyle: "normal",
  },
  appleSignInButton: {
    // width:  Dimensions.get('screen').width-100,
    height: 61,
    flexDirection: "row",
    alignSelf: "center",
    marginVertical: 6,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#252525",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.37,
    shadowRadius: 7.49,
    elevation: 12,
  },
  signinBtn: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 4,
  },
  btnGoogleIcon: {
    height: 44,
    width: 44,
    justifyContent: "space-between",
  },
  btnGoogleText: {
    marginHorizontal: 2,
    fontFamily: FontFamily.InterRegular,
    fontSize: 18,
    lineHeight: 21,
    color: "#000",
  },
  btnIcon: { height: 28, width: 23.13, marginRight: 10, resizeMode: "contain" },

  btnText: {
    // marginHorizontal: 15,
    fontFamily: FontFamily.InterRegular,
    fontSize: 20,
    color: "#1d1d1d",
  },
  socialButtons: {
    alignSelf: "center",
    flexDirection: "row",
  },
  bottomLinks: {
    bottom: 15,
    alignSelf: "center",
    width: Dimensions.get("screen").width - 80,
  },
  linktext: {
    marginVertical: 1,
    fontStyle: "normal",
    fontFamily: FontFamily.InterRegular,
    fontSize: 14,
    textAlign: "center",
    color: "#fff",
  },
  primaryText: {
    fontStyle: "normal",
    fontFamily: FontFamily.InterSemiBold,
    fontSize: 14,
    color: "#0595F7",
  },
  signinInText: {
    justifyContent: "center",
    alignSelf: "center",
    fontStyle: "normal",
    fontFamily: FontFamily.InterBold,
    fontSize: 16,
    textAlign: "center",
    letterSpacing: 0.2,
    // marginHorizontal: "5%",
    color: "#fff",
    marginLeft: 5,
  },
  signupText: {
    justifyContent: "center",
    alignSelf: "center",
    textAlign: "center",
    fontStyle: "normal",
    fontFamily: FontFamily.InterMedium,
    fontSize: 15,
    marginVertical: 10,
    letterSpacing: 0.2,
    // marginHorizontal: "50%",
    // color: "#fff",
  },
  // textStyle: {
  //   marginVertical: 25,
  // },
});
