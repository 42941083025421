import * as React from "react";
import Svg, { Path } from "react-native-svg";
export const Edit = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={682.667}
    height={682.667}
    viewBox="0 0 512 512"
    {...props}
  >
    <Path d="M448.5 2c-7.3 1.5-15.4 5.1-21.2 9.5-3.2 2.3-12.5 11.1-20.8 19.4l-15 15.1 37.8 37.7 37.7 37.8 16-16c8.9-8.8 17.7-18.5 19.8-21.5 14.2-21.4 11.5-48.9-6.7-67.1C483.4 4.2 465.6-1.4 448.5 2zM283.3 154.2c-68.5 68.6-85.3 86-86.1 88.8-.5 1.9-4.2 20.1-8.2 40.5-4.9 24.6-7.1 37.9-6.7 39.8.9 3.5 3.9 6.5 7.4 7.4 3.8.9 80.1-14.3 84-16.7 1.5-1 40.6-39.6 86.8-85.9l84-84.1-37.5-37.5C386.4 85.9 369.3 69 369 69s-38.8 38.4-85.7 85.2z" />
    <Path d="M52.3 87.5c-19.2 3.5-37 17.1-45.7 35-7 14.4-6.7 4.3-6.4 180.5l.3 158.5 2.2 6.5c3.3 9.6 9 18.6 16.3 26 7.5 7.5 14.9 12.1 24.7 15.5l6.8 2.5h324.4l7.8-2.5c20.1-6.3 35.9-23.1 42-44.6 1.7-6 1.8-12.5 1.8-99.5v-93.1l-3-4.8c-4.4-7.1-11.8-10.9-19.8-10.2-7 .6-12.5 3.9-16.4 9.9l-2.8 4.2-.5 92c-.5 84.2-.6 92.4-2.2 95.2-2.4 4.4-8.1 9.1-12.5 10.4-2.7.8-49 1-158.4.8l-154.6-.3-3.9-2.7c-2.1-1.5-5-4.4-6.4-6.5l-2.5-3.7-.3-155.8c-.2-151.9-.2-155.9 1.7-159.6 1.1-2 3.2-4.9 4.8-6.4 6.3-6.1 1.9-5.8 98.9-5.8 67.3 0 89.7-.3 92.8-1.2 5.7-1.7 11.9-8.2 13.5-14.1 2.7-9.5-1.3-19.5-9.7-24.4l-4.7-2.8-90.5-.2c-71.2-.1-92 .1-97.7 1.2z" />
  </Svg>
);
