import { StyleSheet } from "react-native";

import { FontFamily } from "../../assets/fonts";

export const styles = StyleSheet.create({
  safeAreaContainer: {
    flex: 1,
    // flexDirection: "row",
    backgroundColor: "#fff",
    justifyContent: "space-between",
  },
  menuItemContainer: {
    padding: 10,
    borderLeftWidth: 3,
    borderLeftColor: "transparent",
  },
  menuItemContainerSelected: {
    borderLeftColor: "lightblue",
    borderLeftWidth: 5,
    borderRadius: 5,
  },
  menuItem: {
    fontSize: 18,
    fontFamily: FontFamily.InterMedium,
  },
  menuItemSelected: {
    marginLeft: 10,
  },

  dataContainer: {
    display: "flex",
    marginTop: 10,
    backgroundColor: "#fff",
    alignSelf: "flex-start",

    marginHorizontal: "5%",
    minWidth: 375,
  },
  title: {
    fontSize: 24,
    fontFamily: FontFamily.InterBold,
    marginBottom: 20,
  },
  title1: {
    fontSize: 20,
    color: "black",
    fontFamily: FontFamily.InterMedium,
    padding: 10,
    width: "90%",
    letterSpacing: 0.5,
  },
  countryBox: {
    padding: 10,
    height: "4%",
    marginVertical: "1%",
    minWidth: 90,
    marginLeft: "1%",
    width: "10%",
    borderRadius: 15,
    borderWidth: 1,
    justifyContent: "center",
  },
  countryText: {
    padding: 10,
    fontSize: 18,
    color: "black",
    fontFamily: FontFamily.InterSemiBold,
    alignSelf: "center",
  },
  adImage: {
    width: "45%",
    resizeMode: "contain",
    aspectRatio: 0.3,
    // marginTop: -80,
  },
  content: {
    padding: 10,
    width: "85%",
    fontSize: 18,
    color: "black",
    fontFamily: FontFamily.InterRegular,
    lineHeight: 25,
    bottom: "50%",
    marginBottom: 50,
  },
  description: {
    padding: 10,
    width: "90%",
    fontSize: 20,
    color: "black",
    fontFamily: FontFamily.InterRegular,
    lineHeight: 30,
  },
  image: {
    width: "100%",
    height: "90%",
    padding: 10,
    marginHorizontal: "1%",
    backgroundColor: "green",
  },
  pubDate: {
    padding: 10,
    fontSize: 16,
    color: "black",
    fontFamily: FontFamily.InterRegular,
  },
  authorName: {
    fontSize: 16,
    color: "black",
    fontFamily: FontFamily.InterBold,
  },
  field: {
    marginBottom: 16,
    flexDirection: "row",
    justifyContent: "space-between",

    width: "100%",
  },
  textInputFields: {
    marginBottom: 16,
    flexDirection: "column",
    justifyContent: "space-between",

    width: "100%",
  },
  label: {
    fontSize: 16,
    fontFamily: FontFamily.InterBold,
    marginBottom: 10,
    // marginTop: 10,
    textAlign: "left",
  },
  inputlabel: {
    fontSize: 16,
    fontFamily: FontFamily.InterMedium,
    // marginBottom: 10,
    marginTop: 10,
    textAlign: "left",
  },
  value: {
    fontSize: 16,
    color: "gray",

    width: "65%",
  },
  changeIndustryData: {
    borderRadius: 5,
    borderWidth: 1,
    backgroundColor: "#FFF",
    borderColor: "#000",
    marginVertical: 10,
    justifyContent: "center",
    maxWidth: 150,
  },
  saveIndustryData: {
    height: "12%",
    width: "15%",
    borderRadius: 5,
    borderWidth: 2,
    backgroundColor: "#000",
    borderColor: "#000",
    marginVertical: 10,
    justifyContent: "center",
  },
  cancel: {
    height: "12%",
    width: "10%",
    borderRadius: 5,
    borderWidth: 1,
    backgroundColor: "#fff",
    borderColor: "#000",
    marginVertical: 10,
    marginHorizontal: 15,
    justifyContent: "center",
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    backgroundColor: "#fefefe",
    padding: 20,
    borderRadius: 10,
    width: "80%",
  },
  container: {
    position: "relative",
    width: "100%",
    // backgroundColor: editable ? "#fff" : "#f2f2f2",
    marginVertical: 10,
    borderRadius: 5,
    borderColor: "#000",
    borderWidth: 0.5,
  },
  dropdownButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 10,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 4,
  },
  dropdownButtonText: {
    fontSize: 16,
    marginRight: 8,
  },
  dropdownContent: {
    position: "absolute",
    top: "100%",
    width: "100%",
    maxHeight: 200,
    marginTop: 4,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 4,
    backgroundColor: "#fff",
  },
  searchInput: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  optionButton: {
    paddingVertical: 10,
    paddingHorizontal: 12,
  },
  optionText: {
    fontSize: 16,
  },
});
